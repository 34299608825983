import { Icon } from '@goodlok/react-sdk'
import Image from 'next/legacy/image'
import type { FunctionComponent } from 'react'
import type { ShiftsProfileResult } from '../app/data/content/ShiftsProfileFragment'
import styles from './EmployeesQueue.module.sass'

type EmployeesQueueProps = {
	profiles: Array<ShiftsProfileResult>
}

export const EmployeesQueue: FunctionComponent<EmployeesQueueProps> = ({ profiles }) => {
	return (
		<div className={styles.queue}>
			<span>Na směně:</span>
			<div className={styles.items}>
				{profiles.map((profile) => (
					<div key={profile?.id} className={styles.photo} title={profile?.staff?.user?.name}>
						{profile?.staff?.photo ? (
							<Image
								src={profile.staff.photo.url}
								alt={profile.staff.user?.name ?? 'Profile image'}
								layout="fill"
								objectFit="cover"
								sizes="36px"
							/>
						) : (
							<Icon name="friendsOff" />
						)}
					</div>
				))}
			</div>
		</div>
	)
}
