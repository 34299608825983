import { Modal } from '@goodlok/react-sdk'
import { FunctionComponent, useMemo, useState } from 'react'
import { EmployeePageData } from '../utilities/useEmployeePageData'
import { useRole } from './Head'
import { Note } from './Note'
import { ShiftSegment } from './ShiftSegment'
import styles from './ShiftTile.module.sass'

const weekdayShort = ['Ne', 'Po', 'Út', 'St', 'Čt', 'Pá', 'So']

type ShiftTileProps = {
	date: string
	notes?: NonNullable<EmployeePageData['getVenue']>['timetableDays'][number]['notes']
	shiftGroups?: NonNullable<EmployeePageData['getVenue']>['timetableDays'][number]['shiftGroups']
	employee: EmployeePageData['getStaff']
	employeePrivileges: NonNullable<EmployeePageData['getVenueEmployeeRole']>['employeePrivileges']
	updateData: () => Promise<void>
	unassignReasons: NonNullable<EmployeePageData['getVenue']>['enabledUnassignReasons']
}

export const ShiftTile: FunctionComponent<ShiftTileProps> = ({
	date,
	notes,
	shiftGroups,
	employee,
	employeePrivileges,
	updateData,
	unassignReasons,
}) => {
	const [roleId] = useRole()

	const dateFormated = new Date(date)
	const weekday = dateFormated.getDay()

	const positions = useMemo(
		() =>
			(shiftGroups ?? [])
				.filter((position) => position.type?.id === roleId)
				.sort((a, b) => {
					return (
						parseInt(a.position?.start ? a.position?.start?.replace(':', '') : '0') -
						parseInt(b.position?.start ? b.position?.start?.replace(':', '') : '0')
					)
				}),
		[shiftGroups, roleId]
	)

	const [isNotesModalOpen, setIsNotesModalOpen] = useState(false)

	return (
		<div className={styles.wrapper}>
			<button
				type="button"
				className={styles.weekdayDateWrapper}
				onClick={() => setIsNotesModalOpen(true)}
				disabled={(notes ?? []).length === 0}>
				<div className={styles.weekdayDate}>
					<div>{weekdayShort[weekday]}</div>
					<div>{dateFormated.getDate()}</div>
				</div>
			</button>

			<Modal
				isOpen={isNotesModalOpen}
				onRequestClose={() => setIsNotesModalOpen(false)}
				mobileAlign="bottom">
				<div className={styles.modalContent}>
					<h3 className={styles.notesTitle}>
						Poznámky na den {new Date(date).toLocaleDateString('cs')}
					</h3>
					<div className={styles.notes}>
						{notes?.map((note, index) => (
							<Note key={`note-${index}`} id={note.id} role={note.role?.name} text={note.note} />
						))}
					</div>
				</div>
			</Modal>

			<div className={styles.date}>
				{roleId ? (
					<div className={styles.shift}>
						{positions.map((position) => (
							<ShiftSegment
								position={position}
								key={position.id}
								employee={employee}
								employeePrivileges={employeePrivileges}
								updateData={updateData}
								unassignReasons={unassignReasons}
							/>
						))}
					</div>
				) : (
					<div>Chybí role</div>
				)}
			</div>
		</div>
	)
}
