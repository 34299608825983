import { Button, Modal } from '@goodlok/react-sdk'
import type { Dispatch, FunctionComponent, SetStateAction } from 'react'
import styles from './AssignOrSubstituteModal.module.sass'
import { NotImplementedLabel } from './NotImplementedLabel'

type AssignOrSubstituteModalProps = {
	isActive: boolean
	setIsActive: Dispatch<SetStateAction<boolean>>
	setDisableSubstituteAssigning: Dispatch<SetStateAction<boolean>>
	employeeId: string
	shiftGroupId: string
}

export const AssignOrSubstituteModal: FunctionComponent<AssignOrSubstituteModalProps> = ({
	isActive,
	setIsActive,
	setDisableSubstituteAssigning,
	employeeId,
	shiftGroupId,
}) => {
	return (
		<Modal
			isOpen={isActive}
			onRequestClose={() => {
				setIsActive(false)
			}}>
			<div className={styles.content}>
				<h2 className={styles.title}>Chceš se zapsat jako náhradník?</h2>
				<div className={styles.buttons}>
					<Button
						type="button"
						variant="dark"
						outline
						onClick={async () => {
							await fetch('api/assign-substitute', {
								method: 'POST',
								body: JSON.stringify({
									employeeId: employeeId,
									shiftGroupId: shiftGroupId,
								}),
							})
							setIsActive(false)
						}}>
						náhradník
					</Button>
					<Button
						type="button"
						variant="dark"
						outline
						onClick={() => {
							setDisableSubstituteAssigning(false)
							setIsActive(false)
						}}>
						<NotImplementedLabel />
						normální
					</Button>
				</div>
			</div>
		</Modal>
	)
}
