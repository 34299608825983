export function CloseCrossSvg() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
			<path
				d="M1.25762 8.87021L8.82998 1.21701"
				stroke="#333333"
				strokeWidth="1.5"
				strokeLinecap="square"
				strokeLinejoin="round"
			/>
			<path
				d="M8.87019 8.82986L1.21699 1.2575"
				stroke="#333333"
				strokeWidth="1.5"
				strokeLinecap="square"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
