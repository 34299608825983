import { Icon, isDefined } from '@goodlok/react-sdk'
import { useLocalLoading } from '@goodlok/ui'
import clsx from 'clsx'
import { FunctionComponent, useMemo, useRef, useState } from 'react'
import { useOnClickOutside } from '../app/utils/useOnClickOutside'
import { State, resolveShiftSegmentState } from '../utilities/resolveShiftSegmentState'
import { EmployeePageData, useEmployeePageData } from '../utilities/useEmployeePageData'
import { AssignOrSubstituteModal } from './AssignOrSubstituteModal'
import { CloseCrossSvg } from './CloseCross.svg'
import { EmployeesQueue } from './EmployeesQueue'
import { FingerReadySvg } from './FingerReady.svg'
import styles from './ShiftSegment.module.sass'
import { TimeSlider, createTimeString } from './TimeSlider'
import { UnassignModal } from './UnassignModal'

export type ShiftSegmentProps = {
	position: NonNullable<
		NonNullable<ReturnType<typeof useEmployeePageData>['data']>['getVenue']
	>['timetableDays'][number]['shiftGroups'][number]
	employee: NonNullable<ReturnType<typeof useEmployeePageData>['data']>['getStaff']
	employeePrivileges: NonNullable<
		NonNullable<ReturnType<typeof useEmployeePageData>['data']>['getVenueEmployeeRole']
	>['employeePrivileges']
	updateData: () => Promise<void>
	unassignReasons: NonNullable<EmployeePageData['getVenue']>['enabledUnassignReasons']
}

export const ShiftSegment: FunctionComponent<ShiftSegmentProps> = ({
	position,
	employee,
	updateData,
	employeePrivileges,
	unassignReasons,
}) => {
	const employeeId = employee?.shiftsProfile?.id
	const segmentRef = useRef<HTMLButtonElement>(null)
	//variables to initialize correct time for dynamic slider
	const startSplit = (position.position?.start && position.position?.start.split(':')) || [
		'01',
		'00',
	]
	const endSplit = (position.position?.end && position.position?.end.split(':')) || ['01', '00']
	const startHour = parseInt(startSplit[0])
	const startMinute = parseInt(startSplit[1])
	const endHour = parseInt(endSplit[0])
	const endMinute = parseInt(endSplit[1])
	const minuteWindow = (endHour - startHour) * 60 + (endMinute - startMinute)

	const [loading, setLoading] = useLocalLoading()
	const [isDynamicActive, setIsDynamicActive] = useState(false)
	const [isUnassignAlertActive, setIsUnassignAlertActive] = useState(false)
	const [isUnassignModalActive, setIsUnassignModalActive] = useState(false)
	const [disableSubstituteAssigning, setDisableSubstituteAssigning] = useState(false)
	const [isAssignOrSubstituteModalActive, setIsAssignOrSubstituteModalActive] = useState(false)

	const employeesQueue = position.queue?.items
		.map((item) => {
			return item.shift?.assigned
				? item.shift?.swapInfo?.swappedShiftsProfile ?? item.shiftsProfile
				: null
		})
		.filter((profile) => profile && profile?.id !== employeeId)
		.filter(isDefined)

	useOnClickOutside(segmentRef, async () => {
		if (state.name === 'assignedDynamicUnassignable' || state.name === 'assignedUnassignable') {
			setIsUnassignAlertActive(false)
			return
		}
		if (isDynamicActive === false) {
			return
		}
		if (state.name === 'openDynamic') {
			setIsDynamicActive(true)
			return
		}
		setIsDynamicActive(false)
		setLoading(true)
		await fetch(
			state.name === 'inQueue' || state.name === 'inQueueDynamic'
				? '/api/unassign-queue'
				: '/api/assign-queue',
			{
				method: 'POST',
				body: JSON.stringify({
					employeeId,
					shiftGroupId: position.id,
					startTimeDynamic,
					endTimeDynamic,
					positionStart: position.position?.start,
					positionEnd: position.position?.end,
				}),
			}
		)
		await updateData()
		setLoading(false)

		//@TODO: change to confirm assignment instead
	})
	const [shake, setShake] = useState(false)
	const state: State = (() =>
		resolveShiftSegmentState({
			position,
			employee,
			loading,
			disableSubstituteAssigning,
			isDynamicActive,
			employeePrivileges,
		}))()

	const [startTimeDynamic, setStartTimeDynamic] = useState(() => {
		if (state.name === 'openDynamic') {
			return createTimeString(0.25, minuteWindow, startHour, startMinute, true)
		} else {
			return undefined
		}
	})
	const [endTimeDynamic, setEndTimeDynamic] = useState(() => {
		if (state.name === 'openDynamic') {
			return createTimeString(0.75, minuteWindow, startHour, startMinute, false)
		} else {
			return undefined
		}
	})

	const startEndTime = useMemo(() => {
		if (
			state.name === 'openDynamicActive' ||
			state.name === 'inQueueDynamic' ||
			state.name === 'assignedDynamic'
		) {
			return null
		}
		return `${position.position?.start}-${position.position?.end}`
	}, [position.position?.end, position.position?.start, state.name])

	const startEndTimeDynamic = useMemo(() => {
		const item = position.queue?.items.find((item) => item.shiftsProfile?.id === employeeId)
		if (state.name === 'inQueueDynamic' || state.name === 'assignedDynamic') {
			return `${item?.start}-${item?.end}`
		}
		return null
	}, [employeeId, position.queue?.items, state.name])

	const swappedShift = position.shifts.find(
		(shift) =>
			shift.swapInfo?.confirmed === true && shift.swapInfo.swappedShiftsProfile?.id === employeeId
	)

	return (
		<>
			<button
				type="button"
				ref={segmentRef}
				key={position.id}
				className={clsx(
					styles.assignButtonWrapper,
					state.name === 'notAssigned' && styles.disabled,
					state.name === 'assigned' && styles.confirmed,
					state.name === 'assignedDynamic' && styles.confirmed,
					state.name === 'assignedDynamicUnassignable' && styles.confirmed,
					state.name === 'assignedUnassignable' && styles.confirmed,
					state.name === 'inQueue' && styles.inQueue,
					state.name === 'inQueueDynamic' && styles.inQueueDynamic,
					state.name === 'pendingInQueue' && styles.pendingInQueue,
					state.name === 'assigning' && styles.assigning,
					state.name === 'unassigning' && styles.unassigning,
					state.name === 'openDynamicActive' && styles.openDynamicActive,
					state.name === 'openSubstitution' && styles.openSubstitution,
					state.name === 'openOrSubstitute' && styles.openOrSubstitute,
					state.name === 'assignedSubstitute' && styles.assignedSubstitute,
					state.name === 'substituted' && styles.substituted,
					state.name === 'substituting' && styles.confirmed,

					shake && styles.shake
				)}
				onAnimationEnd={() => {
					setShake(false)
				}}
				onClick={async () => {
					if (state.interactive === false) {
						setShake(true)
						return
					}
					if (state.name === 'assigned' || state.name === 'assignedDynamic') {
						setIsUnassignModalActive(true)
						return
					}
					if (
						state.name === 'assignedDynamicUnassignable' ||
						state.name === 'assignedUnassignable'
					) {
						setIsUnassignAlertActive(true)
						return
					}
					if (state.name === 'openDynamic') {
						setIsDynamicActive(true)
						return
					}
					//@TODO finish this
					if (state.name === 'openSubstitution') {
						setLoading(true)
						await fetch('api/assign-substitute', {
							method: 'POST',
							body: JSON.stringify({
								employeeId,
								shiftGroupId: position.id,
							}),
						})
						await updateData()
						setLoading(false)
						//@TODO: call api to assign substitute to a shift and change substitution state
						return
					}
					if (state.name === 'openOrSubstitute') {
						setIsAssignOrSubstituteModalActive(true)
						return
					}
					setIsDynamicActive(false)
					setLoading(true)
					await fetch(
						state.name === 'inQueue' || state.name === 'inQueueDynamic'
							? '/api/unassign-queue'
							: '/api/assign-queue',
						{
							method: 'POST',
							body: JSON.stringify({
								employeeId,
								shiftGroupId: position.id,
								startTimeDynamic,
								endTimeDynamic,
							}),
						}
					)
					await updateData()
					// @TODO: await new data after update
					setLoading(false)
				}}>
				<div className={styles.assignButton}>
					{(['open', 'openDynamic'] as Array<State['name']>).includes(state.name) &&
						employeePrivileges?.assign && (
							<div className={clsx(styles.openIcon)}>
								<Icon name="plus" />
							</div>
						)}

					{(
						['inQueue', 'pendingInQueue', 'assigning', 'inQueueDynamic'] as Array<State['name']>
					).includes(state.name) && (
						<div className={styles.finger}>
							{/* @TODO should be handled via <Icon> component  */}
							<FingerReadySvg />
						</div>
					)}
					{(['notAssigned', 'unassigned'] as Array<State['name']>).includes(state.name) && (
						<svg className={styles.cross}>
							<path strokeWidth="1.5" d={'M15 0 L15 30 L15 30 L15 0 Z'} color="0x000000"></path>
							<path strokeWidth="1.5" d={'M0 15 L30 15 L30 15 L0 15 Z'} color="0x000000"></path>
						</svg>
					)}
					{(
						[
							'assigned',
							'assignedDynamic',
							'openDynamicActive',
							'assignedDynamicUnassignable',
							'assignedUnassignable',
						] as Array<State['name']>
					).includes(state.name) && (
						<div
							className={clsx(
								styles.tick,
								state.name === 'openDynamicActive' && styles.tickBlack
							)}></div>
					)}

					{(
						[
							'openSubstitution',
							'openOrSubstitute',
							'assignedSubstitute',
							'substituted',
							'substituting',
						] as Array<State['name']>
					).includes(state.name) && (
						<div className={styles.substituteIcon}>
							<Icon name="team" />
						</div>
					)}
				</div>
				<div className={styles.buttonText}>
					{startEndTime && <div className={styles.startEndTime}>{startEndTime}</div>}
					{startEndTimeDynamic && (
						<div className={styles.startEndTimeDynamic}>{startEndTimeDynamic}</div>
					)}
					<div className={styles.statusText}>
						{state.label}
						{/* @TODO: remove after debug */}
						{/* ({state.name.toString()}) */}

						{state.name === 'substituting' && ' ' + swappedShift?.shiftsProfile?.staff?.user?.name}

						{state.name === 'pendingInQueue' && (
							<div className={styles.clockTextAlign}>
								<div className={styles.clockOutside}>
									<div className={styles.clockInside}></div>
								</div>
							</div>
						)}
					</div>
				</div>
				{isUnassignAlertActive && (
					//TODO: style this and tidy up the code
					<div
						onClick={(event) => {
							event.stopPropagation()
						}}
						className={styles.unassignConfirm}>
						<button
							type="button"
							onClick={async () => {
								setIsUnassignAlertActive(false)
								setLoading(true)
								await fetch('/api/unassign-shift', {
									method: 'POST',
									body: JSON.stringify({
										employeeId,
										shiftGroupId: position.id,
									}),
								})
								await fetch('/api/unassign-queue', {
									method: 'POST',
									body: JSON.stringify({
										employeeId,
										shiftGroupId: position.id,
										startTimeDynamic,
										endTimeDynamic,
									}),
								})
								console.log('employeeId: ', employeeId)
								await updateData()
								// @TODO: await new data after update
								setLoading(false)
							}}>
							<svg className={styles.unassignCross}>
								<path strokeWidth="1.5" d={'M15 0 L15 30 L15 30 L15 0 Z'} color="0x000000"></path>
								<path strokeWidth="1.5" d={'M0 15 L30 15 L30 15 L0 15 Z'} color="0x000000"></path>
							</svg>
						</button>
					</div>
				)}
				{state.name === 'openDynamicActive' && (
					<div
						className={styles.timeSlider}
						onClick={(event) => {
							event.stopPropagation()
						}}>
						<div className={styles.sliderStyle}>
							<TimeSlider
								endTimeDynamic={endTimeDynamic}
								setEndTimeDynamic={setEndTimeDynamic}
								startTimeDynamic={startTimeDynamic}
								setStartTimeDynamic={setStartTimeDynamic}
								start={position.position?.start || '01'}
								end={position.position?.end || '01'}
							/>
						</div>
						<div
							className={styles.closeDynamicTime}
							onClick={async () => {
								setIsDynamicActive(false)
								setStartTimeDynamic(
									createTimeString(0.25, minuteWindow, startHour, startMinute, true)
								)
								setEndTimeDynamic(
									createTimeString(0.75, minuteWindow, startHour, startMinute, false)
								)
							}}>
							<div className={styles.closeDynamicCross}>
								<CloseCrossSvg />
							</div>
						</div>
					</div>
				)}
				{employeesQueue && employeesQueue?.length > 0 && (
					<EmployeesQueue profiles={employeesQueue} />
				)}
			</button>

			<UnassignModal
				isActive={isUnassignModalActive}
				setIsActive={setIsUnassignModalActive}
				employeeId={employeeId ?? ''}
				shiftGroupId={position.id}
				unassignReasons={unassignReasons}
			/>
			<AssignOrSubstituteModal
				isActive={isAssignOrSubstituteModalActive}
				setIsActive={setIsAssignOrSubstituteModalActive}
				setDisableSubstituteAssigning={setDisableSubstituteAssigning}
				employeeId={employeeId ?? ''}
				shiftGroupId={position.id}
			/>
		</>
	)
}
