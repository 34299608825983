import type { FunctionComponent } from 'react'

export const NotImplementedLabel: FunctionComponent = () => {
	return (
		<div style={{ position: 'relative' }}>
			<span
				style={{
					position: 'absolute',
					right: '0',
					color: 'red',
					fontSize: '0.5rem',
					border: '1px solid red',
					borderRadius: '0.25rem',
					padding: '0 0.125rem',
					backgroundColor: 'rgba(255, 255, 255, 0.8)',
					transform: 'translate(25%, -50%)',
				}}>
				Not implemented
			</span>
		</div>
	)
}
