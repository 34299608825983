import type { ShiftSegmentProps } from '../components/ShiftSegment'

export type State = {
	interactive: boolean
	name:
		| 'open'
		| 'inQueue'
		| 'openDynamic'
		| 'inQueueDynamic'
		| 'openDynamicActive'
		| 'assignedUnassignable'
		| 'assignedDynamicUnassignable'
		| 'assigned'
		| 'assignedDynamic'
		| 'openSubstitution'
		| 'openOrSubstitute'
		// ⬇️ noninteractive states by default
		| 'notAssigned'
		| 'unassigned'
		| 'pendingInQueue'
		| 'assigning'
		| 'unassigning'
		| 'assignedSubstitute'
		| 'substituted'
		| 'substituting'
	label: string
}

export const resolveShiftSegmentState = (options: {
	position: ShiftSegmentProps['position']
	employee: ShiftSegmentProps['employee']
	loading: boolean
	disableSubstituteAssigning: boolean
	isDynamicActive: boolean
	employeePrivileges: ShiftSegmentProps['employeePrivileges']
}): State => {
	const {
		position,
		employee,
		loading,
		disableSubstituteAssigning,
		isDynamicActive,
		employeePrivileges,
	} = options
	const employeeId = employee?.shiftsProfile?.id

	const assigned = position.queue?.items.some(
		(queueItem) => queueItem.shiftsProfile?.id === employeeId && queueItem.shift?.locked
	)
	const inQueue = position.queue?.items.some((item) => item.shiftsProfile?.id === employeeId)
	const isDynamic = position.position?.isDynamic
	//const test = position.shifts.includes((shift) => shift.)
	const substituteRequested = position.shifts.some(
		(shift) =>
			shift.swapInfo?.requested === true &&
			shift.swapInfo.confirmed === false &&
			shift.swapInfo.swappedShiftsProfile?.id !== employeeId
	)
	const allLocked = position.shifts.every((shift) => shift.locked === true)

	const assignedSubstitute = position.shifts.some(
		(shift) =>
			shift.swapInfo?.swappedShiftsProfile?.id === employeeId && shift.swapInfo?.confirmed === true
	)
	const confirmedSubstitute = position.shifts.some(
		(shift) =>
			shift.swapInfo?.confirmed === true && shift.swapInfo?.swappedShiftsProfile?.id !== employeeId
	)
	const confirmedSubstituting = position.shifts.some(
		(shift) =>
			shift.swapInfo?.confirmed === true && shift.swapInfo.swappedShiftsProfile?.id === employeeId
	)
	const canSelfUnassign = employee?.shiftsProfile?.allowSelfUnassigning ?? false
	const unassignedShift = position.queue?.items.find(
		(queueItem) => Boolean(queueItem.unassignInfo) && queueItem.shiftsProfile?.id === employeeId
	)
	if (loading) {
		if (inQueue) {
			return {
				interactive: false,
				name: 'unassigning',
				label: 'Odhlašuji...',
			}
		} else {
			return {
				interactive: false,
				name: 'assigning',
				label: 'Přihlašuji...',
			}
		}
	}
	if (substituteRequested && allLocked && !disableSubstituteAssigning) {
		return {
			interactive: Boolean(employeePrivileges?.assign),
			name: 'openSubstitution',
			label: 'Otevřená náhrada',
		}
	}
	if (confirmedSubstitute) {
		return {
			interactive: false,
			name: 'substituted',
			label: 'Potvrzená náhrada',
		}
	}
	if (confirmedSubstituting) {
		return {
			interactive: false,
			name: 'substituting',
			label: 'Nahrazuji',
		}
	}
	if (unassignedShift) {
		return {
			interactive: false,
			name: `unassigned`,
			label: `Odhlášeno - ${unassignedShift.unassignInfo?.reason?.name ?? 'Neznámý důvod'}`,
		}
	}
	if (assigned && isDynamic && canSelfUnassign) {
		return {
			interactive: Boolean(employeePrivileges?.unassign),
			name: 'assignedDynamicUnassignable',
			label: 'Přihlášeno',
		}
	}
	if (assigned && canSelfUnassign) {
		return {
			interactive: Boolean(employeePrivileges?.unassign),
			name: 'assignedUnassignable',
			label: 'Přihlášeno',
		}
	}
	if (assigned && isDynamic) {
		return {
			interactive: Boolean(employeePrivileges?.unassign),
			name: 'assignedDynamic',
			label: 'Přihlášeno',
		}
	}
	if (assigned) {
		return {
			interactive: true,
			name: 'assigned',
			label: 'Přihlášeno',
		}
	}
	const notAssigned =
		allLocked &&
		(!inQueue ||
			position.shifts.every(
				(shift) =>
					shift.queueItem?.shiftsProfile?.id !== employeeId &&
					shift.queueItem?.shiftsProfile?.id !== undefined
			))
	if (assignedSubstitute) {
		return {
			interactive: false,
			name: 'assignedSubstitute',
			label: 'Přihlášeno jako náhradník',
		}
	}
	if (notAssigned) {
		return {
			interactive: false,
			name: 'notAssigned',
			label: 'Nepřihlášeno',
		}
	}
	const pendingInQueue = inQueue && allLocked
	if (pendingInQueue) {
		return {
			interactive: false,
			name: 'pendingInQueue',
			label: 'Čekám na potvrzení',
		}
	}
	if (inQueue && isDynamic) {
		return {
			interactive: true,
			name: 'inQueueDynamic',
			label: 'Jsem k dispozici',
		}
	}
	if (inQueue) {
		return {
			interactive: true,
			name: 'inQueue',
			label: 'Jsem k dispozici',
		}
	}
	if (substituteRequested && !disableSubstituteAssigning) {
		return {
			interactive: Boolean(employeePrivileges?.assign),
			name: 'openOrSubstitute',
			label: 'Volné s možností nárady',
		}
	}
	if (!isDynamic) {
		return {
			interactive: Boolean(employeePrivileges?.assign),
			name: 'open',
			label: 'Volná směna',
		}
	}
	if (isDynamicActive) {
		return {
			interactive: Boolean(employeePrivileges?.assign),
			name: 'openDynamicActive',
			label: 'Volné',
		}
	}
	return {
		interactive: Boolean(employeePrivileges?.assign),
		name: 'openDynamic',
		label: 'Volné',
	}
}
