import {
	GraphQLTypes,
	InputType,
	Selector,
} from '@goodlok/sdk/generated/content_role_shifts_manager'

export const UnassignReasonFragment = () =>
	Selector('UnassignReason')({
		id: true,
		name: true,
	})

export type UnassignReasonResult = InputType<
	GraphQLTypes['UnassignReason'],
	ReturnType<typeof UnassignReasonFragment>
>
