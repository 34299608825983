import { AppBar, Icon, Warning } from '@goodlok/react-sdk'
import { Thunder } from '@goodlok/sdk/generated/content_role_admin'
import { fromThunder } from '@goodlok/sdk/zeus/fromThunder'
import { useMirrorLoading } from '@goodlok/ui'
import { shortName } from '../pages/api/app.webmanifest'
import { useEmployeePageData } from '../utilities/useEmployeePageData'
import { Footer } from './Footer'
import { Head } from './Head'
import styles from './Layout.module.sass'
import { Timetable } from './Timetable'

// @TODO should be in SDK
export const adminZeus = fromThunder(Thunder, {
	url: `${process.env.BACKEND_URL}/content`,
})

export function Layout() {
	const pageData = useEmployeePageData()

	useMirrorLoading(pageData.isLoading)

	return (
		<div className={styles.wrapper}>
			<AppBar name={shortName} icon={<Icon name="shiftsOn" />} />
			<main className={styles.main}>
				<Head employee={pageData.data?.getStaff} />

				{pageData.data ? (
					<Timetable
						venue={pageData.data.getVenue}
						employee={pageData.data.getStaff}
						employeePrivileges={pageData.data.getVenueEmployeeRole?.employeePrivileges}
						updateData={async () => {
							await pageData.refetch()
						}}
					/>
				) : pageData.isLoading ? (
					<div className={styles.warning}>
						<Warning text="Pro zobrazení obsahu této stránky musíte být přiřazeni k provozovně a mít nastavené zaměstnanecké role." />
					</div>
				) : null}
				<Footer />
			</main>
		</div>
	)
}
