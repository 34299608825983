import { Fragment, FunctionComponent, useMemo, useRef } from 'react'
import { isDeadlineEnabled } from '../utilities/isDeadlineEnabled'
import { useEmployeePageData } from '../utilities/useEmployeePageData'
import { Deadline } from './Deadline'
import { useRole } from './Head'
import { HeadMonth } from './HeadMonth'
import { Note } from './Note'
import { ShiftTile } from './ShiftTile'
import styles from './Timetable.module.sass'

export type TimetableProps = {
	venue: NonNullable<ReturnType<typeof useEmployeePageData>['data']>['getVenue']
	employee: NonNullable<ReturnType<typeof useEmployeePageData>['data']>['getStaff']
	employeePrivileges: NonNullable<
		NonNullable<ReturnType<typeof useEmployeePageData>['data']>['getVenueEmployeeRole']
	>['employeePrivileges']
	updateData: () => Promise<void>
}

export const Timetable: FunctionComponent<TimetableProps> = ({
	venue,
	employee,
	employeePrivileges,
	updateData,
}) => {
	//uwu
	let daysSinceLastLockedDay = 0
	const deadlineLineRef = useRef<HTMLDivElement>(null)

	const [selectedRoleId] = useRole(employee)

	const today = useMemo(() => venue?.timetableDays[0], [venue?.timetableDays])
	const notes = useMemo(
		() => today?.notes.filter((note) => note.role?.id === selectedRoleId),
		[selectedRoleId, today?.notes]
	)

	if (!Boolean(employeePrivileges?.read)) {
		return <div>Chybí práva pro zobrazování směn</div>
	}

	return (
		<div className={styles.wrapper}>
			{today && notes && notes.length > 0 && (
				<div className={styles.notes}>
					<h3 className={styles.notesTitle}>
						Poznámky pro den {new Date(today.date).toLocaleDateString()}
					</h3>
					{notes.map((note, index) => (
						<Note key={`note-${index}`} id={note.id} role={note.role?.name} text={note.note} />
					))}
				</div>
			)}
			{venue?.timetableDays.map((day, dayIndex) => {
				const deadlineTemplate = venue.deadlineTemplate
				const deadlineStart = new Date(deadlineTemplate?.startDate || '1')
				const renderedDay = new Date(day.date)
				const deadlinePeriod = (deadlineTemplate && deadlineTemplate.period) || 1

				//magic to calculate where the deadlineLine should be
				day.shiftGroups.every((shiftGroup) => {
					return shiftGroup.shifts.every((shift) => shift.locked === true)
				}) && day.type === 'open'
					? (daysSinceLastLockedDay = 0)
					: daysSinceLastLockedDay++

				while (deadlineStart.getTime() < renderedDay.getTime()) {
					deadlineStart.setUTCDate(deadlineStart.getUTCDate() + 1)
				}

				const lastDayMonth =
					dayIndex > 0 && new Date(venue.timetableDays[dayIndex - 1].date).getMonth()
				const thisDayMonth = new Date(day.date).getMonth()
				const show = Boolean(daysSinceLastLockedDay === deadlinePeriod + 1)
				return (
					day.date && (
						<Fragment key={day.id}>
							{lastDayMonth !== thisDayMonth && (
								<>
									<div className={styles.monthDeadline}>
										<HeadMonth month={thisDayMonth} />
									</div>

									{isDeadlineEnabled(deadlineTemplate) && dayIndex === 0 && (
										<div className={styles.deadlineTicker}>
											<Deadline
												getDeadlineTemplate={deadlineTemplate}
												gotoDeadlineLine={() => {
													deadlineLineRef.current?.scrollIntoView({
														behavior: 'smooth',
														block: 'center',
													})
												}}
											/>
										</div>
									)}
								</>
							)}
							<div className={styles.tileSpacing}>
								{isDeadlineEnabled(deadlineTemplate) && show && (
									<div className={styles.deadlineLine} ref={deadlineLineRef}></div>
								)}
								{day.shiftGroups.length !== 0 && (
									<ShiftTile
										date={day.date}
										notes={day.notes.filter((note) => note.role?.id === selectedRoleId)}
										shiftGroups={day?.shiftGroups}
										employee={employee}
										employeePrivileges={employeePrivileges}
										updateData={updateData}
										unassignReasons={venue.enabledUnassignReasons}
									/>
								)}
							</div>
						</Fragment>
					)
				)
			})}
		</div>
	)
}
