import styles from './HeadMonth.module.sass'

const monthNames = [
	'Leden',
	'Únor',
	'Březen',
	'Duben',
	'Květen',
	'Červen',
	'Červenec',
	'Srpen',
	'Září',
	'Říjen',
	'Listopad',
	'Prosinec',
]

export function HeadMonth(props: { month: number }) {
	return <div className={styles.wrapper}>{monthNames[props.month]}</div>
}
