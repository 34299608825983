import { useGoodlokAuth } from '@goodlok/react-sdk'
import { useQuery } from '@tanstack/react-query'
import { adminZeus } from '../components/Layout'

export const useVenues = (options?: { filterByStaff?: boolean }) => {
	const auth = useGoodlokAuth()
	const { staffId } = auth

	return useQuery(['venues', staffId] as const, async (q) => {
		const staffId = q.queryKey[1]

		if (!staffId) {
			return null
		}

		const { listVenue: venues } = await adminZeus('query')({
			listVenue: [
				{
					filter: options?.filterByStaff ? { staffMembers: { id: { in: [staffId] } } } : undefined,
				},
				{
					id: true,
					name: true,
					employeeRoles: [
						{
							filter: options?.filterByStaff
								? { role: { shiftsProfiles: { staff: { id: { eq: staffId } } } } }
								: undefined,
						},
						{
							role: [
								{},
								{
									id: true,
									name: true,
								},
							],
						},
					],
				},
			],
		})
		return venues
	})
}
