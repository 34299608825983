import { Selector } from '@goodlok/sdk/generated/content_role_admin'
import { thunder } from '../app/data/thunder'

const EmployeeQueueItemFragment = () =>
	Selector('EmployeeQueueItem')({
		id: true,
		start: true,
		end: true,
		queue: [
			{},
			{
				shiftGroup: [
					{},
					{
						day: [{}, { date: true }],
					},
				],
			},
		],
		shift: [{}, { id: true, assigned: true }],
	})

export const employeeUnassignMutation = async (
	employeeId: string,
	shiftGroupId: string,
	reasonId: string
) => {
	const { getEmployeeQueueItem: queueItem } = await thunder.shiftsManager('query')({
		getEmployeeQueueItem: [
			{
				by: {
					shiftsProfile: { id: employeeId },
					queue: { shiftGroup: { id: shiftGroupId } },
				},
			},
			EmployeeQueueItemFragment(),
		],
	})

	if (queueItem === null || !queueItem?.queue?.shiftGroup?.day?.date) {
		return { ok: false, errorMessage: 'Shift not found' }
	}

	const startHours = parseInt(queueItem.start?.split(':')[0] ?? '0')
	const startMinutes = parseInt(queueItem.start?.split(':')[1] ?? '0')

	const endHours = parseInt(queueItem.end?.split(':')[0] ?? '0')
	const endMinutes = parseInt(queueItem.end?.split(':')[1] ?? '0')

	//@TODO: fix minutes getting parsed as NaN
	const hours = Math.floor(
		((endHours > startHours ? (endHours - startHours) * 60 : (endHours + 24 - startHours) * 60) +
			(endMinutes - startMinutes)) /
			60
	)

	const { updateEmployeeQueueItem } = await thunder.shiftsManager('mutation')({
		updateEmployeeQueueItem: [
			{
				by: { id: queueItem.id },
				data: {
					unassignInfo: {
						upsert: {
							create: { reason: { connect: { id: reasonId } }, hoursMissed: hours },
							update: { reason: { connect: { id: reasonId } }, hoursMissed: hours },
						},
					},
					shift: queueItem.shift
						? {
								update: { assigned: null },
						  }
						: undefined,
				},
			},
			{ ok: true, errorMessage: true },
		],
	})

	if (queueItem.shift?.id) {
		const { updateTimetableShift } = await thunder.shiftsManager('mutation')({
			updateTimetableShift: [
				{
					by: { id: queueItem.shift.id },
					data: { assigned: null, queueItem: { disconnect: true } },
				},
				{
					ok: true,
					errorMessage: true,
				},
			],
		})
		if (!updateTimetableShift.ok) {
			return { ok: false, error: updateTimetableShift.errorMessage }
		}
	}

	return {
		ok: updateEmployeeQueueItem.ok ?? false,
		error: updateEmployeeQueueItem.errorMessage,
	}
}
