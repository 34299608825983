import useSize from '@react-hook/size'
import React, { FunctionComponent, useCallback, useRef, useState } from 'react'
import styles from './TimeSlider.module.sass'

export function createTimeString(
	offsetPosition: number,
	minuteWindow: number,
	startHour: number,
	startMinute: number,
	isStart: boolean
) {
	const minutes = startHour * 60 + startMinute + Math.floor(offsetPosition * minuteWindow)
	const startIncrement = isStart ? 0 : 1
	const returned = `${((Math.floor(minutes / 60) + startIncrement) % 24)
		.toString()
		.padStart(2, '0')}:${(Math.floor((Math.floor(minutes) % 60) / 15) * 15)
		.toString()
		.padStart(2, '0')}`

	return returned
}

type TimeSliderProps = {
	start: string
	end: string
	endTimeDynamic?: string
	setEndTimeDynamic: (time: string) => void
	startTimeDynamic?: string
	setStartTimeDynamic: (time: string) => void
}

export const TimeSlider: FunctionComponent<TimeSliderProps> = ({
	start,
	end,
	endTimeDynamic,
	setEndTimeDynamic,
	startTimeDynamic,
	setStartTimeDynamic,
}) => {
	const target = React.useRef<HTMLDivElement>(null)
	const [wrapperWidth] = useSize(target)
	const [isMoving1, setIsMoving1] = useState(false)
	const [isMoving2, setIsMoving2] = useState(false)

	const startPosition = useRef({ x: 0 })
	const [offsetPosition1, setOffsetPosition1] = useState(0.25)
	const [offsetPosition2, setOffsetPosition2] = useState(0.75)

	const startSplit = start.split(':')
	const endSplit = end.split(':')

	const startHour = parseInt(startSplit[0])
	const startMinute = parseInt(startSplit[1])

	const endHour = parseInt(endSplit[0])
	const endMinute = parseInt(endSplit[1])

	const minuteWindow = (endHour - startHour) * 60 + (endMinute - startMinute) - 60
	//
	if (typeof startTimeDynamic === 'undefined') {
		setStartTimeDynamic(createTimeString(0.25, minuteWindow, startHour, startMinute, true))
	}
	if (typeof endTimeDynamic === 'undefined') {
		setEndTimeDynamic(createTimeString(0.75, minuteWindow, startHour, startMinute, true))
	}

	// const widthCoefficient = minuteWindow / wrapperWidth

	const onPointerDown1 = useCallback(
		(event: React.PointerEvent<HTMLDivElement>) => {
			startPosition.current = {
				x: event.clientX - offsetPosition1 * wrapperWidth,
			}
			setIsMoving1(true)
		},
		[offsetPosition1, wrapperWidth]
	)
	const onPointerUp1 = useCallback((event: React.PointerEvent<HTMLDivElement>) => {
		event.currentTarget.releasePointerCapture(event.pointerId)
		setIsMoving1(false)
	}, [])
	const onPointerMove1 = useCallback(
		(event: React.PointerEvent<HTMLDivElement>) => {
			event.preventDefault()

			const newPosition = { x: event.clientX }
			const newOffsetPosition = {
				x: newPosition.x - startPosition.current.x,
			}
			const newOffsetPercent = newOffsetPosition.x / wrapperWidth
			if (newOffsetPosition.x !== 0) {
				event.currentTarget.setPointerCapture(event.pointerId)
			}
			if (newOffsetPosition.x / wrapperWidth > offsetPosition2 && offsetPosition2 <= 1) {
				setOffsetPosition2(newOffsetPercent)
				setEndTimeDynamic(
					createTimeString(offsetPosition2, minuteWindow, startHour, startMinute, false)
				)
			}
			if (offsetPosition1 >= 0 && offsetPosition1 <= 1) {
				setOffsetPosition1(newOffsetPercent)
			}
			if (newOffsetPosition.x < 0) {
				setOffsetPosition1(0)
			}
			if (newOffsetPosition.x > wrapperWidth) {
				setOffsetPosition1(1)
				setOffsetPosition2(1)
			}

			setStartTimeDynamic(
				createTimeString(offsetPosition1, minuteWindow, startHour, startMinute, true)
			)
		},
		[
			minuteWindow,
			offsetPosition1,
			offsetPosition2,
			setEndTimeDynamic,
			setStartTimeDynamic,
			startHour,
			startMinute,
			wrapperWidth,
		]
	)
	const onPointerDown2 = useCallback(
		(event: React.PointerEvent<HTMLDivElement>) => {
			startPosition.current = {
				x: event.clientX - offsetPosition2 * wrapperWidth,
			}
			setIsMoving2(true)
		},
		[offsetPosition2, wrapperWidth]
	)
	const onPointerUp2 = useCallback((event: React.PointerEvent<HTMLDivElement>) => {
		event.currentTarget.releasePointerCapture(event.pointerId)
		setIsMoving2(false)
	}, [])
	const onPointerMove2 = useCallback(
		(event: React.PointerEvent<HTMLDivElement>) => {
			event.preventDefault()

			const newPosition = { x: event.clientX }
			const newOffsetPosition = {
				x: newPosition.x - startPosition.current.x,
			}
			const newOffsetPercent = newOffsetPosition.x / wrapperWidth

			if (newOffsetPosition.x !== 0) {
				event.currentTarget.setPointerCapture(event.pointerId)
			}
			if (newOffsetPosition.x / wrapperWidth < offsetPosition1 && offsetPosition2 >= 0) {
				setOffsetPosition1(newOffsetPercent)
				setStartTimeDynamic(
					createTimeString(offsetPosition1, minuteWindow, startHour, startMinute, true)
				)
			}
			if (offsetPosition1 >= 0 && offsetPosition1 <= 1) {
				setOffsetPosition2(newOffsetPercent)
			}
			if (newOffsetPosition.x < 0) {
				setOffsetPosition1(0)
				setOffsetPosition2(0)
			}
			if (newOffsetPosition.x > wrapperWidth) {
				setOffsetPosition2(1)
			}
			setEndTimeDynamic(
				createTimeString(offsetPosition2, minuteWindow, startHour, startMinute, false)
			)
		},
		[
			wrapperWidth,
			offsetPosition1,
			offsetPosition2,
			setEndTimeDynamic,
			minuteWindow,
			startHour,
			startMinute,
			setStartTimeDynamic,
		]
	)

	return (
		<div className={styles.wrapper} ref={target}>
			<div className={styles.displayTime1}>
				{startTimeDynamic}
				{/*`${Math.floor((offsetPosition1 * minuteWindow) / 60) + startHour}`.padStart(2, '0')}:
				{`${Math.round(offsetPosition1 * minuteWindow) % 60}`.padStart(2, '0')*/}
			</div>
			<div
				className={styles.slider1}
				onPointerDown={onPointerDown1}
				onPointerUp={onPointerUp1}
				onPointerMove={isMoving1 ? onPointerMove1 : undefined}
				style={{
					'--TimeSlider-offset': `${offsetPosition1}`,
				}}>
				<div className={styles.handle1}></div>
			</div>
			<div
				className={styles.slider2}
				onPointerDown={onPointerDown2}
				onPointerUp={onPointerUp2}
				onPointerMove={isMoving2 ? onPointerMove2 : undefined}
				style={{
					'--TimeSlider-offset': `${offsetPosition2}`,
				}}>
				<div className={styles.greyBox}></div>
				<div className={styles.handle2}></div>
			</div>
			<div className={styles.displayTime2}>
				{
					endTimeDynamic /*`${Math.floor((offsetPosition2 * minuteWindow) / 60) + startHour}`.padStart(2, '0')}:
				{`${Math.round(offsetPosition2 * minuteWindow) % 60}`.padStart(2, '0')*/
				}
				{/* @TODO: displaying hours might need fixing for strange minute values */}
			</div>
		</div>
	)
}
