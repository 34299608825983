import { useGoodlokAuth } from '@goodlok/react-sdk'
import { useQuery } from '@tanstack/react-query'
import { DeadlineTemplateFragment } from '../app/data/content/DeadlineTemplateFragment'
import { ShiftsProfileFragment } from '../app/data/content/ShiftsProfileFragment'
import { UnassignReasonFragment } from '../app/data/content/UnassignReasonFragment'
import { useRole, useVenue } from '../components/Head'
import { adminZeus } from '../components/Layout'
import { useVenues } from './useVenues'

export const useEmployeePageData = () => {
	const auth = useGoodlokAuth()
	const { staffId } = auth
	const now = new Date()
	const today = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()))
	const [venueId, setVenueId] = useVenue()
	const [roleId, setRoleId] = useRole()
	const venues = useVenues({ filterByStaff: true })

	return useQuery(
		[useEmployeePageData.name, staffId, roleId, venues, venueId] as const,
		async (q) => {
			const staffId = q.queryKey[1]
			const roleId = q.queryKey[2]

			if (typeof staffId !== 'string' || !venues.data) {
				return null
			}

			if (venues.data.length === 0 || venues.data[0]?.employeeRoles.length === 0) {
				// notify(
				// 	'Není vybraná pobočka nebo zaměstanecká role, kontaktujte manažera pobočky.',
				// 	'error'
				// )
				return null
			}
			// ⬇️ @TODO this should probably be handled somewhere else
			if (!venueId) {
				setVenueId(venues.data[0].id)
			}

			if (
				!roleId ||
				!venues.data
					.find((venue) => venue.id === venueId)
					?.employeeRoles.find((employeeRole) => employeeRole.role?.id === roleId)
			) {
				setRoleId(venues.data[0].employeeRoles[0].role?.id ?? null)
			}
			// ⬆️

			const data = await adminZeus('query')({
				getVenue: [
					{ by: { id: venueId ?? venues.data[0].id } },
					{
						timetableDays: [
							{
								filter: {
									date: {
										gte: today.toISOString(),
									},
								},
								orderBy: [{ date: 'asc' }],
							},
							{
								date: true,
								id: true,
								type: true,
								shiftGroups: [
									{},
									{
										id: true,
										type: [
											{},
											{
												id: true,
												name: true,
											},
										],
										position: [
											{},
											{
												id: true,
												name: true,
												start: true,
												end: true,
												isDynamic: true,
											},
										],
										shifts: [
											{
												orderBy: [{ assigned: 'asc' }],
											},
											{
												locked: true,
												id: true,
												shiftsProfile: [{}, ShiftsProfileFragment()],
												swapInfo: [
													{},
													{
														id: true,
														assigned: true,
														confirmed: true,
														requested: true,
														swappedShiftsProfile: [
															{},
															{
																id: true,
															},
														],
													},
												],
												queueItem: [{}, { id: true, shiftsProfile: [{}, { id: true }] }],
											},
										],
										queue: [
											{},
											{
												id: true,
												items: [
													{},
													{
														id: true,
														shiftsProfile: [{}, ShiftsProfileFragment()],
														start: true,
														end: true,
														shift: [
															{},
															{
																locked: true,
																assigned: true,
																swapInfo: [
																	{},
																	{
																		id: true,
																		swappedShiftsProfile: [{}, ShiftsProfileFragment()],
																	},
																],
															},
														],
														unassignInfo: [
															{},
															{
																id: true,
																reason: [
																	{},
																	{
																		id: true,
																		name: true,
																	},
																],
																hoursMissed: true,
															},
														],
													},
												],
											},
										],
									},
								],
								notes: [
									// @TODO filter be selected row once query is refetched after role select
									{ orderBy: [{ order: 'asc' }] },
									{
										id: true,
										note: true,
										role: [
											{},
											{
												id: true,
												name: true,
											},
										],
									},
								],
							},
						],
						deadlineTemplate: [{}, DeadlineTemplateFragment()],
						enabledUnassignReasons: [{ orderBy: [{ order: 'asc' }] }, UnassignReasonFragment()],
					},
				],
				getVenueEmployeeRole: [
					{ by: { role: { id: roleId }, venue: { id: venueId } } },
					{
						employeePrivileges: [
							{},
							{
								read: true,
								unassign: true,
								assign: true,
							},
						],
					},
				],
				getStaff: [
					{ by: { id: staffId } },
					{
						id: true,
						user: [
							{},
							{
								name: true,
							},
						],
						shiftsProfile: [
							{},
							{
								id: true,
								roles: [{}, { id: true, name: true }],
								allowSelfUnassigning: true,
								staff: [
									{},
									{
										user: [
											{},
											{
												name: true,
											},
										],
									},
								],
							},
						],
					},
				],
				listShiftsProfile: [
					{},
					{
						id: true,
						staff: [
							{},
							{
								user: [
									{},
									{
										name: true,
									},
								],
							},
						],
						roles: [
							{},
							{
								id: true,
								name: true,
							},
						],
					},
				],
			})

			return { ...data }
		},
		{
			keepPreviousData: true,
			refetchInterval: parseInt(process.env.REFETCH_INTERVAL_MILLISECONDS ?? '10000'),
		}
	)
}

export type EmployeePageData = NonNullable<ReturnType<typeof useEmployeePageData>['data']>
