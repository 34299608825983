import {
	Button,
	ImageSelect,
	ImageSelectOption,
	useCustomerInfo,
	useGoodlokIsShiftsManager,
} from '@goodlok/react-sdk'
import { FunctionComponent, useMemo } from 'react'
import { useStorageBackedState } from 'use-storage-backed-state'
import { useEmployeePageData } from '../utilities/useEmployeePageData'
import { useVenues } from '../utilities/useVenues'
import styles from './Head.module.sass'

export const useRole = (employee?: { shiftsProfile?: { roles: { id: string }[] } }) =>
	useStorageBackedState(employee?.shiftsProfile?.roles[0]?.id ?? null, 'role')

export const useVenue = (venue?: { id: string }) =>
	useStorageBackedState(venue?.id ?? null, 'venue')

export type HeadProps = {
	employee: NonNullable<ReturnType<typeof useEmployeePageData>['data']>['getStaff']
}

export const Head: FunctionComponent<HeadProps> = ({ employee }) => {
	const [roleId, setRoleId] = useRole()
	const [venueId, setVenueId] = useVenue()
	const venues = useVenues({ filterByStaff: true })

	const isShiftsManager = useGoodlokIsShiftsManager()
	const userInfo = useCustomerInfo()

	const activeVenue = useMemo(() => {
		if (!venues.data) {
			return null
		}
		// if (!venueId) {
		// 	setVenueId(venues.data[0].id)
		// }
		return venues.data.find((venue) => venue.id === venueId)
	}, [venueId, venues])

	const roleOptions = useMemo(() => {
		const items: ImageSelectOption[] = []
		const employeeRoles = activeVenue?.employeeRoles.filter(
			(employeeRole) =>
				employee?.shiftsProfile?.roles.map((item) => item.id).includes(employeeRole.role?.id ?? '')
		)

		if ((employeeRoles ?? []).length > 0) {
			employeeRoles?.forEach((employeeRole) => {
				const name = employeeRole.role?.name
				items.push({
					label: name ? name : 'Role bez názvu',
					value: employeeRole.role?.id ?? '',
				})
			})
		}

		return items
	}, [activeVenue?.employeeRoles, employee?.shiftsProfile?.roles])

	const selectedRoleValue = useMemo(() => {
		if (!venues.data) {
			return null
		}
		// if (!roleId) {
		// 	setRoleId(activeVenue?.employeeRoles[0].id ?? null)
		// }

		return roleOptions.find((option) => option.value === roleId)?.value
	}, [roleId, roleOptions, venues.data])

	const venueOptions = useMemo(() => {
		const items: ImageSelectOption[] = []
		if ((venues.data ?? []).length > 0) {
			venues.data?.forEach((venue) => {
				const name = venue.name
				items.push({
					label: name ? name : 'Podnik bez názvu',
					value: venue.id,
				})
			}) ?? []
		}
		return items
	}, [venues])

	return (
		<>
			<div className={styles.wrapper}>
				<div className={styles.top}>
					<h3 className={styles.name}>{employee?.user?.name || userInfo.data?.meta?.name}</h3>
					{isShiftsManager && (
						<Button type="link" href="/admin" variant="primary">
							Admin
						</Button>
					)}
				</div>
			</div>
			<div className={styles.filters}>
				<div className={styles.filtersIn}>
					{(employee?.shiftsProfile?.roles ?? []).length > 1 && (
						<ImageSelect
							name="role"
							// label="Role:"
							options={roleOptions}
							onChange={(option) => {
								setRoleId(option?.value || null)
							}}
							value={selectedRoleValue ?? undefined}
							isMulti={false}
						/>
					)}

					{(venues.data ?? []).length > 0 && (
						<ImageSelect
							name="venue"
							// label="Podnik:"
							options={venueOptions}
							onChange={(option) => {
								setVenueId(option?.value || null)
							}}
							value={
								// kinda hacky, solves the problem of the venueId being set to a venue that is not available for the current user or venue
								venueId && venues.data?.map((venue) => venue.id).includes(venueId ?? '')
									? venueId
									: undefined
							}
							isMulti={false}
							menuAlignment="left"
						/>
					)}
				</div>
			</div>
		</>
	)
}
