import { Button, Modal, useGoodlokIsShiftsManager } from '@goodlok/react-sdk'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import clsx from 'clsx'
import { Dispatch, FunctionComponent, SetStateAction } from 'react'
import { UnassignReasonResult } from '../app/data/content/UnassignReasonFragment'
import { api } from '../utilities/api'
import { employeeUnassignMutation } from '../utilities/employeeUnassignMutation'
import styles from './UnassignModal.module.sass'

type UnassignModalProps = {
	isActive: boolean
	setIsActive: Dispatch<SetStateAction<boolean>>
	employeeId: string
	shiftGroupId: string
	unassignReasons: UnassignReasonResult[]
}

export const UnassignModal: FunctionComponent<UnassignModalProps> = ({
	isActive,
	setIsActive,
	employeeId,
	shiftGroupId,
	unassignReasons,
}) => {
	const queryClient = useQueryClient()
	const unassignMutation = useMutation(
		async (props: { employeeId: string; shiftGroupId: string; reasonId: string }) => {
			await employeeUnassignMutation(props.employeeId, props.shiftGroupId, props.reasonId)
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['adminPageData', 'employeePageData'])
			},
		}
	)
	const unassignAndRemoveFromQueueMutation = api.shifts.unassignAndRemoveFromQueue.useMutation({
		onSuccess: () => {
			queryClient.invalidateQueries(['adminPageData', 'employeePageData'])
		},
	})
	const requestSubstituteMutation = api.shifts.requestSubstitute.useMutation({
		onSuccess: () => {
			queryClient.invalidateQueries(['adminPageData', 'employeePageData'])
		},
	})

	const isShiftsManager = useGoodlokIsShiftsManager()

	return (
		<Modal
			isOpen={isActive}
			onRequestClose={() => {
				setIsActive(false)
			}}>
			<div className={styles.content}>
				<h2 className={styles.title}>Uveďte důvod k odhlášení</h2>
				<div className={styles.buttons}>
					{unassignReasons.map((unassignReason, index) => (
						<div className={styles.button} key={index}>
							<Button
								type="button"
								variant="dark"
								disabled={unassignMutation.isLoading || requestSubstituteMutation.isLoading}
								outline
								onClick={async () => {
									unassignMutation.mutate({
										employeeId: employeeId,
										shiftGroupId: shiftGroupId,
										reasonId: unassignReason.id,
									})
									setIsActive(false)
								}}>
								{unassignReason.name}
							</Button>
						</div>
					))}

					<div className={styles.button}>
						<Button
							type="button"
							variant="dark"
							disabled={unassignMutation.isLoading || requestSubstituteMutation.isLoading}
							outline
							onClick={() => {
								requestSubstituteMutation.mutate({
									employeeId: employeeId,
									shiftGroupId: shiftGroupId,
								})
								setIsActive(false)
							}}>
							Náhrada
						</Button>
					</div>

					{isShiftsManager && (
						<div className={clsx(styles.button, styles.remove)}>
							<Button
								type="button"
								variant="danger"
								disabled={unassignMutation.isLoading || requestSubstituteMutation.isLoading}
								outline
								onClick={() => {
									unassignAndRemoveFromQueueMutation.mutate({
										employeeId: employeeId,
										shiftGroupId: shiftGroupId,
									})
									setIsActive(false)
								}}>
								Odstranit
							</Button>
						</div>
					)}
				</div>
			</div>
		</Modal>
	)
}
