import clsx from 'clsx'
import { FunctionComponent, useEffect, useState } from 'react'
import { useEmployeePageData } from '../utilities/useEmployeePageData'
import styles from './Deadline.module.sass'

function getRemainingTime(to: number): number {
	const now = new Date().getTime()
	return to - now
}

function formatTime(timestamp: number): string {
	const days = Math.floor(timestamp / (1000 * 60 * 60 * 24))
	const hours = Math.floor((timestamp % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
	//const minutes = Math.floor((timestamp % (1000 * 60 * 60)) / (1000 * 60))
	//const seconds = Math.floor((timestamp % (1000 * 60)) / 1000)
	//return `${days} dni, ${hours} hodin, ${minutes} minut, ${seconds} sekund`
	return `${days}d: ${hours}h`
}

type DeadlineProps = {
	getDeadlineTemplate: NonNullable<
		NonNullable<ReturnType<typeof useEmployeePageData>['data']>['getVenue']
	>['deadlineTemplate']
	gotoDeadlineLine: () => void
}

export const Deadline: FunctionComponent<DeadlineProps> = ({
	getDeadlineTemplate,
	gotoDeadlineLine,
}) => {
	const [remainingTime, setRemainingTime] = useState<number | null>(null)
	const incrementTime = new Date(getDeadlineTemplate?.startDate || '')
	const period = getDeadlineTemplate?.period || 1
	const cutoff = getDeadlineTemplate?.cutoff || 1
	const now = new Date()

	while (incrementTime < now) {
		incrementTime.setUTCDate(incrementTime.getUTCDate() + period)
	}
	incrementTime.setDate(incrementTime.getDate() + cutoff)
	const countdownDate = incrementTime

	useEffect(() => {
		const interval = setInterval(() => {
			setRemainingTime(getRemainingTime(countdownDate.getTime()))
			return () => clearInterval(interval)
		}, 1000)
	}, [countdownDate])
	const closed = remainingTime && remainingTime < 0
	const black = remainingTime && remainingTime / (1000 * 60 * 60 * 24) > 1
	const red = !(remainingTime && remainingTime / (1000 * 60 * 60 * 24) > 1) && !closed
	return (
		<button
			type="button"
			onClick={() => {
				gotoDeadlineLine()
			}}
			className={clsx(
				styles.wrapper,
				closed && styles.wrapperClosed,
				black && styles.wrapperBlack,
				red && styles.wrapperRed
			)}>
			UZAVÍRKA: {formatTime(remainingTime || 0)}
		</button>
	)
}
