import { Icon } from '@goodlok/react-sdk'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { FunctionComponent } from 'react'
import { thunder } from '../app/data/thunder'
import styles from './Note.module.sass'

export type NoteProps = {
	id: string
	text: string
	role?: string
	isAdmin?: boolean
}

export const Note: FunctionComponent<NoteProps> = ({ id, text, role, isAdmin = false }) => {
	const queryClient = useQueryClient()

	const deleteMutation = useMutation(
		async (data: { id: string }) => {
			return thunder.admin('mutation')({
				deleteTimetableDayNote: [
					{
						by: { id: data.id },
					},
					{
						ok: true,
						errorMessage: true,
					},
				],
			})
		},
		{
			onSuccess: (data) => {
				if (data.deleteTimetableDayNote?.ok === false) {
					alert('Failed to delete note')
					console.error(data.deleteTimetableDayNote.errorMessage)
				}
				queryClient.invalidateQueries({ queryKey: ['adminPageData'] })
			},
		}
	)

	return (
		<div className={styles.wrapper}>
			<p className={styles.text}>
				{role && (
					<>
						<strong>{role}</strong>:
					</>
				)}{' '}
				{text}
			</p>
			{isAdmin && (
				<button
					className={styles.remove}
					type="button"
					aria-label="Odstranit"
					title="Odstranit"
					onClick={() => {
						deleteMutation.mutate({ id })
					}}>
					<Icon name="cross" />
				</button>
			)}
		</div>
	)
}
