import {
	MapType,
	ModelTypes,
	ValueTypes,
	ZeusSelect,
} from '@goodlok/sdk/generated/content_role_admin'

export function DeadlineTemplateFragment() {
	return ZeusSelect<ValueTypes['DeadlineTemplate']>()({
		startDate: true,
		period: true,
		cutoff: true,
		closed: true,
	})
}

export type DeadlineTemplateResult = MapType<
	ModelTypes['DeadlineTemplate'],
	ReturnType<typeof DeadlineTemplateFragment>
>
