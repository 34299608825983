import {
	MapType,
	ModelTypes,
	ValueTypes,
	ZeusSelect,
} from '@goodlok/sdk/generated/content_role_admin'

export function ShiftsProfileFragment() {
	return ZeusSelect<ValueTypes['ShiftsProfile']>()({
		id: true,
		staff: [
			{},
			{
				id: true,
				user: [{}, { name: true, email: true }],
				photo: [{}, { url: true }],
			},
		],
	})
}

export type ShiftsProfileResult = MapType<
	ModelTypes['ShiftsProfile'],
	ReturnType<typeof ShiftsProfileFragment>
>
